import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import SermonsSlider from "../components/sermons-slider";
import FindUsOn from "../components/find-us-on";
import Youtube from "../img/icon/youtube-orange.inline.svg";
import Spotify from "../img/icon/spotify-orange.inline.svg";
import ApplePodCasts from "../img/icon/apple-orange.inline.svg";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import AniLink from "gatsby-plugin-transition-link/AniLink";
class Sermon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data } = this.props;
    const sermon = data.contentfulSermon;
    const sermons = data?.allContentfulSermon?.nodes;

    return (
      <Layout>
        <Helmet>
          <title>Sermon - {sermon.title}</title>
          <meta property="og:title" content={`Sermon - ${sermon.title}`} />
          <meta name="description" content={sermon?.description.description} />
          <meta
            property="og:description"
            content={sermon?.description.description}
          />
          <meta
            property="og:image"
            content={`https://img.youtube.com/vi/${sermon?.youtubeEmbedId}/hqdefault.jpg`}
          />
        </Helmet>

        <section className="single-sermon-header">
          <div className="hero-background-wrapper">
            <div className="container">
              <iframe
                className="background-video"
                src={`https://youtube.com/embed/${sermon?.youtubeEmbedId}?autoplay=1&enablejsapi=1`}
                frameBorder="0"
                allowFullScreen="true"
                allow="autoplay;"
              ></iframe>
            </div>
          </div>
        </section>
        <section className="single-sermon-middle ">
          <div className="content container">
            <div className="social-container">
              <AniLink fade to="/sermons" className="link-back-white">
                Back To Sermons
              </AniLink>
              <div>
                <ul>
                  <li>
                    <OutboundLink
                      target="_blank"
                      href={`https://youtube.com/watch?v=${sermon?.youtubeEmbedId}`}
                    >
                      <Youtube />
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink target="_blank" href={sermon?.spotifyLink}>
                      <Spotify />
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink target="_blank" href={sermon?.appleLink}>
                      <ApplePodCasts />
                    </OutboundLink>
                  </li>
                </ul>
              </div>
            </div>{" "}
            d
            <div className="sermon-data">
              <div className="sermon-title">
                <h2>
                  {sermon?.title}
                  {/* <span className="title-orange">
                    {sermon?.highlightedTitle}
                  </span> */}
                </h2>
                <p>{sermon.reference}</p>
              </div>
              <div className="description">
                {documentToReactComponents(sermon?.description?.json)}
              </div>
            </div>
          </div>
        </section>
        <section className="watch-more-sermons ">
          <h2 className="watch-more-heading">Watch More Sermons</h2>

          <SermonsSlider
            data={sermons.filter((item) => item?.id !== sermon?.id)}
          />
        </section>
        <section>
          <FindUsOn />
        </section>
      </Layout>
    );
  }
}

export default Sermon;

export const pageQuery = graphql`
  query SermonBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulSermon(slug: { eq: $slug }) {
      id
      slug
      title
      reference
      spotifyLink
      appleLink
      description {
        json
        description
      }
      youtubeEmbedId
    }
    allContentfulSermon(sort: { fields: date, order: DESC }) {
      nodes {
        date
        id
        title
        reference
        spotifyLink
        appleLink
        description {
          json
        }
        slug
        youtubeEmbedId
      }
    }
  }
`;
